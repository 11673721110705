import React, { useState, useCallback, useEffect } from "react";
import "../style/font-style.css";
import { Box, Grid } from "@material-ui/core";
import AOS from "aos";
import "aos/dist/aos.css";
import { useStaticQuery, graphql, Link } from "gatsby";
import Head from "../components/head";
import Header from "../components/Header_normal";
import Footer from "../components/Footer_nomal";
import Coworking from "../components/Placearea";
import Navigation from "../components/navigation";
import Img from "gatsby-image";
import "../css/base.css";
import WrokEnv from "../components/workEnv";
import TsuriEvent from "../components/tsuricationButton";
import ConceptBgImg from "../../static/images/concept_bg_img.png";
import Concept from "../components/concept";
import Carousel from "../components/Carousel";
import WorcarionBase from "../components/WorcationBase";
import News from "../components/news";
import mizutama from "../../static/images/mizutama.png";
import Map from "../components/map";
import NEWMAP from "../components/map";
import Fish from "../components/fish";
import FVVideo from "../../static/fishing-short.mp4";
import BoxTypo from "../components/BoxTypo";
import Faq from "../components/faq";
import { TwitterTimelineEmbed } from "react-twitter-embed";
import WrapTypo from "../components/WrapTypo";
import InstagramEmbeded from "../components/InstagramEmbeded";

export default function Home() {
  const [height, setHeight] = useState(0);
  const measuredRef = useCallback((node) => {
    if (node !== null) {
      setHeight((node.getBoundingClientRect().width * 9) / 16);
    }
    console.log("高さ変更");
  }, []);
  if (typeof document !== `undefined`) {
    AOS.init({
      initClassName: "aos-init", // class applied after initialization
      animatedClassName: "aos-animate", // class applied on animation
      disableMutationObserver: false, // disables automatic mutations' detections (advanced)
      delay: 600, // values from 0 to 3000, with step 50ms
      duration: 2000, // values from 0 to 3000, with step 50ms
      easing: "ease", // default easing for AOS animations
      once: true,
      anchorPlacement: "top-top",
    });
  }
  const data = useStaticQuery(graphql`
    query {
      allMicrocmsNews(sort: { fields: date, order: DESC }) {
        edges {
          node {
            id
            date
            content
            title
            newsId
          }
        }
      }
      ContestsResult: file(relativePath: { eq: "banar_contests_result.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      JTPImg: file(relativePath: { eq: "JTPImg.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ShonaiTravSiteImg: file(relativePath: { eq: "shonaiTravSiteImg.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      SuidenImg: file(relativePath: { eq: "suidenImg.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      pc_top: file(relativePath: { eq: "pc_top.png" }) {
        childImageSharp {
          fluid(maxWidth: 3500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Group126: file(relativePath: { eq: "Group126.png" }) {
        childImageSharp {
          fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      sp_top: file(relativePath: { eq: "sp_top.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      cta: file(relativePath: { eq: "cta_contact.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      fv_img: file(relativePath: { eq: "fv_img.png" }) {
        childImageSharp {
          fluid(maxWidth: 2500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      work_title: file(relativePath: { eq: "work_title.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      tsuri_title: file(relativePath: { eq: "tsuri_title.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      tomari_title: file(relativePath: { eq: "tomari_title.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      work_title_sp: file(relativePath: { eq: "sp_work_title.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      tsuri_title_sp: file(relativePath: { eq: "sp_tsuri_title.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      tomari_title_sp: file(relativePath: { eq: "sp_tomari_title.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      PrevArrow: file(relativePath: { eq: "prevArrow.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      NextArrow: file(relativePath: { eq: "nextArrow.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      odyssey: file(relativePath: { eq: "odyssey.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      tsuri1: file(relativePath: { eq: "fishing1.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      tsuri2: file(relativePath: { eq: "fishing2.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      tsuri3: file(relativePath: { eq: "fishing3.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      tsuri4: file(relativePath: { eq: "fishing4.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      tsuri5: file(relativePath: { eq: "fishing5.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      tsuri6: file(relativePath: { eq: "fishing6.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      tsuri7: file(relativePath: { eq: "fishing7.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      tsuri8: file(relativePath: { eq: "fishing8.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      tsuri9: file(relativePath: { eq: "fishing9.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      hotel1: file(relativePath: { eq: "hotel1.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      hotel2: file(relativePath: { eq: "hotel2.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      hotel3: file(relativePath: { eq: "hotel3.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      hotel4: file(relativePath: { eq: "hotel4.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      hotel5: file(relativePath: { eq: "hotel5.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      hotel6: file(relativePath: { eq: "hotel6.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      hotel7: file(relativePath: { eq: "hotel7.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      hotel8: file(relativePath: { eq: "hotel8.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      hotel9: file(relativePath: { eq: "hotel9.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      hotel10: file(relativePath: { eq: "hotel10.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      coworking1: file(relativePath: { eq: "coworking1.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      coworking2: file(relativePath: { eq: "coworking2.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      coworking3: file(relativePath: { eq: "coworking3.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      coworking4: file(relativePath: { eq: "coworking4.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      coworking5: file(relativePath: { eq: "coworking5.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      coworking6: file(relativePath: { eq: "coworking6.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      coworking7: file(relativePath: { eq: "coworking10.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      coworking8: file(relativePath: { eq: "coworking8.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      checklist: file(relativePath: { eq: "howto.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      anaBanner: file(relativePath: { eq: "anaBanner.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      paper1: file(relativePath: { eq: "paper1.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      paper2: file(relativePath: { eq: "paper2.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      paper3: file(relativePath: { eq: "paper4.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const tsuri = [
    {
      link: "https://www.ana.co.jp/travelandlife/article/001696/",
      img: data.tsuri1.childImageSharp.fluid,
    },
    {
      link: "http://joho.shonai-hama.net/?page_id=50",
      img: data.tsuri2.childImageSharp.fluid,
    },
    {
      link: "http://joho.shonai-hama.net/?page_id=65",
      img: data.tsuri3.childImageSharp.fluid,
    },
    {
      link: "http://joho.shonai-hama.net/?page_id=169",
      img: data.tsuri4.childImageSharp.fluid,
    },
    {
      link: "http://joho.shonai-hama.net/?page_id=47",
      img: data.tsuri5.childImageSharp.fluid,
    },
    {
      link: "http://joho.shonai-hama.net/?page_id=52",
      img: data.tsuri6.childImageSharp.fluid,
    },
    {
      link: "https://www.ana.co.jp/travelandlife/article/001743/4/",
      img: data.tsuri7.childImageSharp.fluid,
    },
    {
      link: "https://mokkedano.net/spot/30023",
      img: data.tsuri8.childImageSharp.fluid,
    },
    {
      link: "http://joho.shonai-hama.net/?page_id=168",
      img: data.tsuri9.childImageSharp.fluid,
    },
  ];

  const hotel = [
    {
      link: "https://www.iroribinosato.info/denden.html",
      img: data.hotel1.childImageSharp.fluid,
    },
    {
      link: "http://www.yunohamaonsen.com/",
      img: data.hotel2.childImageSharp.fluid,
    },
    {
      link: "http://www.atsumi-spa.or.jp/",
      img: data.hotel3.childImageSharp.fluid,
    },

    {
      link: "https://www.suiden-terrasse.yamagata-design.com/",
      img: data.hotel5.childImageSharp.fluid,
    },
    {
      link: "http://yaotome.in.net/",
      img: data.hotel6.childImageSharp.fluid,
    },
    {
      link: "https://www.navishonai.jp/spot/lodging.html",
      img: data.hotel7.childImageSharp.fluid,
    },
    {
      link: "https://www.yutagawaonsen.com/",
      img: data.hotel8.childImageSharp.fluid,
    },
    {
      link:
        "https://www.ana.co.jp/domtour/booking/csm/search/DSDP0010/init?checkInDaysCnt=30&adultCnt=1&districtCd=08&regionCd=405&areaCd=448",
      img: data.hotel4.childImageSharp.fluid,
    },
    {
      link:
        "https://www.ana.co.jp/domtour/booking/csm/search/DSDP0010/init?checkInDaysCnt=30&adultCnt=1&districtCd=08&regionCd=405&areaCd=447",
      img: data.hotel9.childImageSharp.fluid,
    },
    {
      link: "https://yamagata-yurari.com/",
      img: data.hotel10.childImageSharp.fluid,
    },
  ];
  const coworking = [
    {
      link: "https://www.facebook.com/umiterasuyuraisonokaze",
      img: data.coworking1.childImageSharp.fluid,
    },
    {
      link: "https://eki-ichi.com/",
      img: data.coworking2.childImageSharp.fluid,
    },
    {
      link: "https://www.suiden-terrasse.yamagata-design.com/",
      img: data.coworking3.childImageSharp.fluid,
    },
    {
      link: "https://sanroku.jp/",
      img: data.coworking4.childImageSharp.fluid,
    },
    {
      link: "https://lhwc.jp/",
      img: data.coworking5.childImageSharp.fluid,
    },
    {
      link: "https://happy-s-mall.com/workspace/",
      img: data.coworking6.childImageSharp.fluid,
    },
    {
      link: "https://www.facebook.com/tsuruokadada/",
      img: data.coworking7.childImageSharp.fluid,
    },
    {
      link: "http://underbar-cowork.com/",
      img: data.coworking8.childImageSharp.fluid,
    },
  ];

  const carouselElems = [
    {
      link:
        "https://www.ana.co.jp/ja/jp/japan-travel-planner/recommendation/itinerary20/?cid=JTP_PTN_SYO_NA_JP72_202107",
      img: data.JTPImg.childImageSharp.fluid,
    },
    {
      link:
        "https://www.ana.co.jp/ja/jp/domestic/promotions/collaboration/feel-shonai/",
      img: data.ShonaiTravSiteImg.childImageSharp.fluid,
    },
    {
      link:
        "https://www.ana.co.jp/ja/jp/domestic/promotions/collaboration/feel-shonai/workation/",
      img: data.SuidenImg.childImageSharp.fluid,
    },
    {
      link: "https://www.odysseyjapan.com/",
      img: data.odyssey.childImageSharp.fluid,
    },
    {
      link: "https://www.ana.co.jp/travelandlife/article/001743/",
      img: data.anaBanner.childImageSharp.fluid,
    },
  ];

  const WorkLocationArray = [
    {
      place: "海テラスゆら",
      lat: 38.72169522059306,
      lng: 139.68580359096674,
      url: "https://www.facebook.com/umiterasuyuraisonokaze",
    },
    {
      place: "エキイチ",
      lat: 38.73931992957246,
      lng: 139.83556062589324,
      url: "https://eki-ichi.com/",
    },
    {
      place: "スイデンテラス",
      lat: 38.746558217891845,
      lng: 139.82223168356515,
      url: "https://www.suiden-terrasse.yamagata-design.com/",
    },
    {
      place: "サンロク",
      lat: 38.916835530658425,
      lng: 139.838930331628,
      url: "https://sanroku.jp/",
    },
    {
      place: "ライトハウス",
      lat: 38.9170415402723,
      lng: 139.85800232036271,
      url: "https://lhwc.jp/",
    },
    {
      place: "S-MALL",
      lat: 38.738636549128756,
      lng: 139.83215341307383,
      url: "https://happy-s-mall.com/workspace/",
    },
  ];

  const FishingLocationArray = [
    {
      place: "ゆら漁港",
      lat: 38.718415169839076,
      lng: 139.68169922589252,
      url: "http://joho.shonai-hama.net/index.php?page_id=54",
    },
    {
      place: "加茂",
      lat: 38.760810465205765,
      lng: 139.72453537124136,
      url: "http://joho.shonai-hama.net/?page_id=50",
    },

    {
      place: "酒田港",
      lat: 38.931493858138154,
      lng: 139.80610526750385,
      url: "http://joho.shonai-hama.net/?page_id=65",
    },
    {
      place: "酒田本港",
      lat: 38.95065042449687,
      lng: 139.81348197426493,
      url: "http://joho.shonai-hama.net/?page_id=169",
    },
    {
      place: "飛島",
      lat: 39.18710124791026,
      lng: 139.5478017105662,
      url: "http://joho.shonai-hama.net/?page_id=47",
    },
    {
      place: "鼠ヶ関港",
      lat: 38.55908240952759,
      lng: 139.54295366099072,
      url: "http://joho.shonai-hama.net/?page_id=52",
    },
    // {
    //   place: "渓流",
    //   lat: "緯度経度を入力してください",
    //   lng: "緯度経度を入力してください",
    //   url: "https://www.ana.co.jp/travelandlife/article/001743/",
    // },
    // {
    //   place: "海釣り",
    //   lat: "緯度経度を入力してください",
    //   lng: "緯度経度を入力してください",
    //   url: "こちらにURLを入れてください",
    // },
  ];
  const StayLocationArray = [
    {
      place: "三川町",
      lat: 38.79523699985459,
      lng: 139.8495744863409,
      url: "https://www.iroribinosato.info/denden.html",
    },
    {
      place: "湯野浜温泉",
      lat: 38.784978121173936,
      lng: 139.754730592857,
      url: "http://www.yunohamaonsen.com/",
    },
    {
      place: "温海温泉",
      lat: 38.61585676828656,
      lng: 139.6102305518552,
      url: "http://www.atsumi-spa.or.jp/",
    },
    {
      place: "八乙女",
      lat: 38.724801149763195,
      lng: 139.69212714064142,
      url: "http://yaotome.in.net/",
    },
    {
      place: "庄内町",
      lat: 38.84464491196596,
      lng: 139.9108526414916,
      url: "https://www.navishonai.jp/spot/lodging.html",
    },
    {
      place: "湯田川温泉",
      lat: 38.69323203243407,
      lng: 139.76965885308132,
      url: "https://www.yutagawaonsen.com/",
    },
    {
      place: "鶴岡",
      lat: 38.73927140091159,
      lng: 139.83669245516668,
      url:
        "https://www.ana.co.jp/domtour/booking/csm/search/DSDP0010/init?checkInDaysCnt=30&adultCnt=1&districtCd=08&regionCd=405&areaCd=448",
    },
    {
      place: "酒田",
      lat: 38.92198190821492,
      lng: 139.8460418811946,
      url:
        "https://www.ana.co.jp/domtour/booking/csm/search/DSDP0010/init?checkInDaysCnt=30&adultCnt=1&districtCd=08&regionCd=405&areaCd=447",
    },
    {
      place: "遊佐町",
      lat: 39.01607807522574,
      lng: 139.90540300228926,
      url: "https://yamagata-yurari.com/",
    },
  ];
  const TransportLocationArray = [
    {
      place: "庄内空港",
      lat: 38.81568162166641,
      lng: 139.78792950505735,
      url: "https://www.shonai-airport.co.jp/",
    },
    // {
    //   place: "海テラス、八乙女",
    //   lat: "緯度を追加してください",
    //   lng: "経度を追加してください",
    //   url: "こちらにURLを入れてください",
    // },
    {
      place: "加茂水族館",
      lat: 38.76185599036296,
      lng: 139.72512089899453,
      url: "こちらにURLを入れてください",
    },
    {
      place: "湯野浜温泉バス停",
      lat: 38.77964522837472,
      lng: 139.75167559871113,
      url: "こちらにURLを入れてください",
    },
  ];

  return (
    <Box width="100%" bgcolor="#fff">
      <Head />
      <Box width="100%" m={"auto"}>
        <Header />
        <Navigation />
        <Box width={["100%", "100%", "80%"]} pt={[10, 15, 0]}>
          {/* <Img
            fluid={data.fv_img.childImageSharp.fluid}
            className="w100"
            data-aos="fade-in"
          /> */}
          <video autoPlay muted loop playsInline className="w100">
            <source className="w100" src={FVVideo} type="video/mp4" />
            Sorry, your browser doesn't support embedded videos.
          </video>
        </Box>
      </Box>
      <Box
        width={["95%", "95%", "80%"]}
        ml={["auto", "auto", "5%"]}
        m={["auto", "auto", "left"]}
        bgcolor="#fff"
        data-aos="fade-in"
      >
        <Concept />
      </Box>
      <Box width={["100%", "100%", "80%"]} ref={measuredRef} pt={[10, 15, 0]}>
        <iframe
          width="100%"
          //16:9=300:x
          //300*9=16x
          //x=300*9/16
          height={height}
          src="https://www.youtube-nocookie.com/embed/0yIuery3bCE?autoplay=1&loop=1&playlist=0yIuery3bCE&rel=0"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </Box>
      <Box
        width={["95%", "95%", "80%"]}
        ml={["auto", "auto", "5%"]}
        m={["auto", "auto", "left"]}
        bgcolor="#fff"
        data-aos="fade-in"
      >
        <BoxTypo
          Tsukubrdgothic
          fs={["24px", "36px", "48px"]}
          ls={["2px"]}
          lh={["200%"]}
          mt={[5, 10, 20]}
          color="#333F69"
          textAlign="center"
        >
          - お知らせ -
        </BoxTypo>
        <Box width={"80%"} maxWidth="800px" m={"auto"}>
          <Img fluid={data.paper2.childImageSharp.fluid} />
        </Box>
        <Box
          width={"80%"}
          maxWidth="800px"
          m={"auto"}
          boxShadow={6}
          my={[3, 6]}
          borderRadius={5}
        >
          <Link to="/contest_result" target="_blank" rel="noopener noreferrer">
            <Img fluid={data.ContestsResult.childImageSharp.fluid} />
          </Link>
        </Box>
        <Link to="/contest_result" target="_blank" rel="noopener noreferrer">
          <BoxTypo
            Tsukubrdgothic
            fs={["12px", "16px", "18px"]}
            ls={["2px"]}
            lh={["200%"]}
            mt={4}
            textAlign="center"
          >
            詳しくはこちら
          </BoxTypo>
        </Link>
      </Box>
      <Box
        width={["95%", "95%", "80%"]}
        ml={["auto", "auto", "5%"]}
        m={["auto", "auto", "left"]}
        bgcolor="#fff"
        data-aos="fade-in"
      >
        <BoxTypo
          Tsukubrdgothic
          fs={["24px", "36px", "48px"]}
          ls={["2px"]}
          lh={["200%"]}
          mt={[5, 10, 20]}
          textAlign="center"
          color="#333F69"
        >
          - キャンペーン -
        </BoxTypo>
        <Box width={"80%"} maxWidth="800px" m={"auto"}>
          <Img fluid={data.paper3.childImageSharp.fluid} />
        </Box>
        <Link to="/campaign" target="_blank" rel="noopener noreferrer">
          <BoxTypo
            Tsukubrdgothic
            fs={["12px", "16px", "18px"]}
            ls={["2px"]}
            lh={["200%"]}
            mt={4}
            textAlign="center"
          >
            詳しくはこちら
          </BoxTypo>
        </Link>
      </Box>

      <WorcarionBase />
      {/* <NEWMAP /> */}
      <Box
        width={["95%", "95%", "75%"]}
        ml={["auto", "auto", "5%"]}
        m={["auto", "auto", "left"]}
        bgcolor="#fff"
      >
        <Box data-aos="fade-in">
          <Box id="work">
            <Img
              fluid={data.work_title.childImageSharp.fluid}
              className="w100 mw1100 ma pc"
            />
            <Img
              fluid={data.work_title_sp.childImageSharp.fluid}
              className="w100 mw1100 ma sp"
            />
          </Box>

          <Coworking
            title={`いつもと違った場所で
            いつもと違う働き方`}
            content={`目の前に広がる日本海、波の音を聞きながら、庄内の大自然を感じ、違った環境でのワークタイム。新しいアイディアがひらめくかも。コワーキングスペースでは、1dayの利用も可能です。スイデンテラスは宿泊利用者向けサービスになります。`}
            pics={coworking}
          />
          <WrokEnv />
        </Box>
        <Box data-aos="fade-in">
          <Box id="tsuri" data-aos="fade-in">
            <Img
              fluid={data.tsuri_title.childImageSharp.fluid}
              className="w100 mw1100 ma pc mt128"
            />
            <Img
              fluid={data.tsuri_title_sp.childImageSharp.fluid}
              className="w100 mw1100 ma sp mt128"
            />
          </Box>
          <Coworking
            title={`都会を離れて釣り時間`}
            content={`これから釣りを始める方からベテランまで、幅広く多くの釣りファンに、海と山に囲まれた自然の中で釣りを楽しんでいただきたい。庄内浜の春夏秋冬、川と海、時間帯によっていろいろな種類の魚を釣ることができる釣り場があります。庄内では、これまでになかった充実した欲張りな「釣り」時間を過ごせるでしょう。`}
            pics={tsuri}
            isOptionLink={[
              {
                link: "https://www.ana.co.jp/travelandlife/article/001743/",
                title: "ANA釣り倶楽部の記事はこちら ＞",
              },

              {
                link: "http://www.johshuya.co.jp/shop/choka.php?s=81",
                title: "庄内浜の釣り情報はこちら ＞",
              },
              {
                link: "https://www6.kaiho.mlit.go.jp/",
                title: "海の安全情報はこちら ＞",
              },
              {
                link: "https://www6.kaiho.mlit.go.jp/watersafety/",
                title: "ウォーターセーフティガイド ＞",
              },
              {
                link:
                  "https://www6.kaiho.mlit.go.jp/watersafety/fishing/index.html",
                title: "釣りの安全情報はこちら ＞",
              },
            ]}
          />
          <Fish />
          <Box
            width={"90%"}
            maxWidth="600px"
            m={"auto"}
            mt={10}
            boxShadow={2}
            borderRadius={30}
          >
            <a href="/checklist" target="_blank" rel="noopener noreferrer">
              <Img fluid={data.checklist.childImageSharp.fluid} />
            </a>
          </Box>
          {/* <TsuriEvent /> */}
        </Box>
        <Box data-aos="fade-in">
          <Box id="hotel" data-aos="fade-in">
            <Img
              fluid={data.tomari_title.childImageSharp.fluid}
              className="w100 mw1100 ma pc mt128"
            />
            <Img
              fluid={data.tomari_title_sp.childImageSharp.fluid}
              className="w100 mw1100 ma sp mt128"
            />
          </Box>
          <Coworking
            title={`心身ともに癒やしの時間`}
            content={`山形県庄内には鳥海山や月山などダイナミックな自然があり、海に沈む夕陽やどこまでも続く庄内平野の絶景を楽しむことができます。「ワーク」と「釣り」の後は、日頃の疲れた身体を温泉でほっこり、癒しの時間「Feel庄内」しませんか。`}
            pics={hotel}
          />
        </Box>
      </Box>
      <Box
        data-aos="fade-in"
        style={{
          backgroundImage: `url(${mizutama})`,
          backgroundRepeat: "no-repeat",
          objectFit: "fit",
        }}
      >
        <Box
          width={["95%", "95%", "75%"]}
          ml={["auto", "auto", "5%"]}
          m={["auto", "auto", "left"]}
        >
          <News data={data.allMicrocmsNews.edges} />
          <Box m={"auto"} width="90%" maxWidth="600px" mt={10} mb={10}>
            <WrapTypo
              className="tac"
              fs="24px"
              ls="10px"
              Tsukubrdgothic
              fw="700"
              mb="32px"
            >
              Twitter
            </WrapTypo>
            <TwitterTimelineEmbed
              sourceType="profile"
              screenName="syo_fishcation"
              options={{ height: 800 }}
            />
          </Box>
          <WrapTypo
            className="tac"
            fs="24px"
            ls="10px"
            Tsukubrdgothic
            fw="700"
            mb="32px"
          >
            Instgram
          </WrapTypo>
          <InstagramEmbeded />
          <Box id="map" data-aos="fade-in">
            <Map
              WorkLocationArray={WorkLocationArray}
              FishingLocationArray={FishingLocationArray}
              StayLocationArray={StayLocationArray}
              TransportLocationArray={TransportLocationArray}
            />
          </Box>
          <Faq />
          <Box data-aos="fade-in">
            <Carousel carouselElems={carouselElems} />
          </Box>
        </Box>
        <Footer />
      </Box>
    </Box>
  );
}
